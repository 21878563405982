import { conform, useForm } from '@conform-to/react';
import { getFieldsetConstraint, parse } from '@conform-to/zod';
import { ActionFunctionArgs, json } from '@remix-run/node';
import { useActionData } from '@remix-run/react';
import { ReactElement } from 'react';
import Content from '~/components/Content';
import Button from '~/components/ui/Button';
import Form from '~/components/ui/Form';
import { LoginSchema } from '~/utils/memberValidation';
import { createUserSession } from '~/utils/session.server';

export const handle = {
    headline: 'Login',
};

export async function action({ request }: ActionFunctionArgs) {
    const formData = await request.formData();
    const submission = await parse(formData, {
        schema: LoginSchema,
        async: true,
    });

    if (submission.intent !== 'submit' || !submission.value) {
        return json(submission);
    }

    return createUserSession(submission.value, '/');
}

export default function Login(): ReactElement {
    const lastSubmission = useActionData<typeof action>();

    const [form, fields] = useForm({
        constraint: getFieldsetConstraint(LoginSchema),
        lastSubmission,
        onValidate({ formData }) {
            return parse(formData, { schema: LoginSchema });
        },
        shouldValidate: 'onBlur',
    });

    return (
        <Content className="max-w-md mx-auto">
            {form.errors.length > 0 ? (
                <div className="bg-red-100 border-2 border-red-500 rounded p-3 mb-3">
                    {form.errors.map(error => (
                        <p>{error}</p>
                    ))}
                </div>
            ) : null}
            <Form method="post" {...form.props} className="space-y-4">
                <Form.InputGroup
                    label={LoginSchema._def.schema.shape.username.description}
                    errors={fields.username.errors}>
                    <Form.Input autoComplete="username" {...conform.input(fields.username)} />
                </Form.InputGroup>
                <Form.InputGroup
                    label={LoginSchema._def.schema.shape.password.description}
                    errors={fields.password.errors}>
                    <Form.Input
                        autoComplete="current-password"
                        {...conform.input(fields.password, { type: 'password' })}
                    />
                </Form.InputGroup>

                <div className="space-x-4 flex justify-end">
                    <Button type="submit" color="primary">
                        Login
                    </Button>
                </div>
            </Form>
        </Content>
    );
}
